import { render, staticRenderFns } from "./WorkoutDashboard.vue?vue&type=template&id=15901a88&scoped=true&"
import script from "./WorkoutDashboard.vue?vue&type=script&lang=ts&"
export * from "./WorkoutDashboard.vue?vue&type=script&lang=ts&"
import style0 from "./WorkoutDashboard.vue?vue&type=style&index=0&id=15901a88&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15901a88",
  null
  
)

export default component.exports