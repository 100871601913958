


































































import { Component, Vue } from 'vue-property-decorator';
import BrandStore from '@/store/modules/brand';
import { ExerciseVideoStatusList, ExerciseVideoStatusType, StatusType, CategoryTypeList, CategoryType } from '@/constants';
import { BrandDocument, ExerciseDocument, ExerciseModel, IExerciseBrandSetup } from '@/models';

interface ICategoryBrandMap {
  category: CategoryType;
  label: string;
  brands: BrandDocument[];
}

interface IExerciseBrand extends IExerciseBrandSetup {
  brandName: string;
  exerciseId: string;
  exerciseName: string;
}

@Component({
  name: 'ExercisDashboard',
})
export default class extends Vue {
  private isSearching: boolean = true;
  private results: any = {};

  private exerciseVideoStatusList: Record<ExerciseVideoStatusType, StatusType<ExerciseVideoStatusType>> = (undefined as unknown) as Record<
    ExerciseVideoStatusType,
    StatusType<ExerciseVideoStatusType>
  >;
  private brands: BrandDocument[] = (undefined as unknown) as BrandDocument[];
  private categoryBrandMap: ICategoryBrandMap[] = (undefined as unknown) as ICategoryBrandMap[];

  public created() {
    this.exerciseVideoStatusList = ExerciseVideoStatusList;
    this.brands = BrandStore.list;
    /**
     * Build the brandCategoryMap
     */
    const categoryBrands = Object.entries(CategoryTypeList).map(([category, label]) => {
      const brands = BrandStore.list.filter(brand => brand.category === category);
      return { category, label, brands } as ICategoryBrandMap;
    });
    this.categoryBrandMap = categoryBrands.filter(categoryBrand => categoryBrand.brands.length > 0);
  }

  public mounted() {
    this.doSearch();
  }

  private async doSearch(): Promise<void> {
    const results: any = {};

    const exercises = await ExerciseModel.find();
    exercises.forEach(exercise => {
      exercise.brandSetup.forEach(brand => {
        const brandId = brand.brandId;
        results.exerciseCount = results.exerciseCount || {};
        results.exerciseCount[brandId] = results.exerciseCount[brandId] || 0;
        results.exerciseCount[brandId]++;
        const status = brand.status;
        results[status] = results[status] || {};
        results[status][brandId] = results[status][brandId] || 0;
        results[status][brandId]++;
      });
    });
    results.overdueBrandExercisesFilmDates = this.overdueBrandExerciseFilmDates(exercises);
    this.results = results;
    this.isSearching = false;
  }

  private overdueBrandExerciseFilmDates(exercises: ExerciseDocument[]) {
    const twoDaysAgo = new Date().setDate(new Date().getDate() - 2);
    const overdueFilmDatePredicate = (brand: IExerciseBrandSetup) => {
      if (!brand.filmDate) {
        return false;
      }
      const filmDate = new Date(brand.filmDate).getTime();
      if (filmDate >= twoDaysAgo) {
        return false;
      }
      return brand.status === ExerciseVideoStatusType.READY_TO_FILM || brand.status === ExerciseVideoStatusType.READY_TO_UPLOAD;
    };
    const overdueBrandExercises = exercises.reduce((acc: IExerciseBrand[], exercise) => {
      const overdueExerciseBrands = exercise.brandSetup.filter(overdueFilmDatePredicate);
      for (const overdueExerciseBrand of overdueExerciseBrands) {
        const brand = this.brands.find(brand => brand.id === overdueExerciseBrand.brandId);
        if (brand) {
          acc.push({
            brandName: brand.name,
            exerciseId: exercise.id,
            exerciseName: exercise.name,
            ...overdueExerciseBrand,
          });
        }
      }
      return acc;
    }, []);

    overdueBrandExercises.sort((a, b) => {
      const sortValue = a.brandName.localeCompare(b.brandName);
      if (sortValue !== 0) {
        return sortValue;
      }
      return a.exerciseName.localeCompare(b.exerciseName);
    });

    return overdueBrandExercises;
  }
}
