














































import { Component, Vue } from 'vue-property-decorator';
import { Storage } from 'aws-amplify';
import { saveAs } from 'file-saver';
import { WorkoutVideoStatusType, ProductTypeList } from '@/constants';
import { WorkoutModel } from '@/models';
import { Helper } from '@/util';
import { ProductType } from '@/API';

interface IWorkoutItem {
  brandId: string;
  brandName: string;
  workoutId: string;
  workoutSequence: number;
  productTypes: string[];
  mainProducType: ProductType;
  fileKey: string;
  fileName: string;
  dateUpdated: string;
}

@Component({
  name: 'WorkoutDashboard',
})
export default class extends Vue {
  private isSearching: boolean = true;
  private results: IWorkoutItem[] = [];
  private downloadingWorkouts: string[] = [];

  public mounted() {
    this.doSearch();
  }

  private async doSearch(): Promise<void> {
    this.isSearching = true;

    this.results = (await WorkoutModel.find())
      .reduce((accumulator, workout): IWorkoutItem[] => {
        workout.productTypeWorkouts.forEach((productTypeSetup): void => {
          if (productTypeSetup.status === WorkoutVideoStatusType.READY_TO_APPROVE && !productTypeSetup.isDisabled) {
            if (!productTypeSetup.isInherited) {
              accumulator.push({
                brandId: workout.workoutBrandId,
                brandName: workout.brand?.name as string,
                workoutId: workout.id,
                workoutSequence: workout.sequence,
                productTypes: [],
                mainProducType: productTypeSetup.productType,
                fileKey: productTypeSetup.productionFile.key,
                fileName: productTypeSetup.productionFile.name,
                dateUpdated: workout.getUpdatedDate(false) as string,
              });
            }

            accumulator[accumulator.length - 1].productTypes.push(ProductTypeList[productTypeSetup.productType]);
          }
        });

        return accumulator;
      }, [] as IWorkoutItem[])
      .sort((a, b): number => {
        const theSame = a.brandName.toLowerCase().localeCompare(b.brandName.toLowerCase());

        if (theSame === 0) {
          return a.workoutSequence - b.workoutSequence;
        } else {
          return theSame;
        }
      });

    this.isSearching = false;
  }

  private gotoWorkoutVideoTab(workoutId: string, productType: ProductType) {
    window.open(Helper.routeHref({ name: 'workout-update', params: { id: workoutId }, query: { step: 'workoutVideo', productType } }), '_blank');
  }

  private gotoWorkout(workoutId: string) {
    Helper.routeTo({ name: 'workout-update', params: { id: workoutId }, query: { step: 'workoutVideo' } });
  }

  private async downloadWorkoutFile(workoutId: string, productType: ProductType): Promise<void> {
    if (this.isDownloading(workoutId, productType)) {
      return;
    }

    this.downloadingWorkouts.push(`${workoutId}-${productType}`);

    const pos = this.downloadingWorkouts.length - 1;
    const result: IWorkoutItem = this.results.find(x => x.workoutId === workoutId && x.mainProducType === productType) as IWorkoutItem;
    const storage = (await Storage.get(result.fileKey, { download: true })) as Record<string, any>;

    saveAs(storage.Body as Blob, result.fileName);

    this.downloadingWorkouts.splice(pos, 1);
  }

  private isDownloading(workoutId: string, productType: ProductType): boolean {
    return this.downloadingWorkouts.includes(`${workoutId}-${productType}`);
  }
}
